<template>
  <div
    class="mt-20"
    :class="{
      'lg:border-primary-1-15lg:mt-56 lg:mb-17': type === 'THREE_CARDS_RIGHT',
      'lg:mt-35 lg:mb-28': type === 'THREE_CARDS_LEFT',
    }"
  >
    <div class="container mx-auto">
      <OfferSlider
        v-if="content && content.length"
        v-slot="{ item }"
        class="mt-0 col-span-2 md:col-auto"
        :items="content"
        :orientation="type === 'THREE_CARDS_LEFT' ? 'left' : 'right'"
        :mobile-options="{
          slidesPerView: 1.6,
          spaceBetween: 20,
          centeredSlides: true,
          loop: true,
        }"
        :desktop-options="{
          slidesPerView: 3,
          spaceBetween: 104,
          centeredSlides: true,
          centerInsufficientSlides: true,
          loop: true,
        }"
        arrows
        centered
      >
        <OfferCard v-bind="item" width="500" height="669" />
      </OfferSlider>
    </div>
  </div>
</template>
<script setup lang="ts">
import { OfferType } from '~/composables/offers';

defineComponent({
  name: 'OffersFragment',
});
const props = defineProps({
  content: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
  type: {
    type: String,
    default: 'THREE_CARDS_LEFT',
  },
});
const { locale } = useI18n();

const dir = computed(() => {
  if (props.type === 'THREE_CARDS_RIGHT' && locale.value === 'en') {
    return 'rtl';
  }

  if (props.type === 'THREE_CARDS_LEFT' && locale.value === 'en') {
    return 'rtl';
  }

  if (props.type === 'THREE_CARDS_LEFT' && locale.value === 'ar') {
    return 'ltr';
  }

  if (props.type === 'THREE_CARDS_RIGHT' && locale.value === 'ar') {
    return 'ltr';
  }
});
</script>
