<template>
  <CarouselWrapper
    :id="String(content?.id) || ''"
    :title="content?.title || ''"
    :type="componentStyle"
    :products="content?.items || []"
  />
</template>
<script setup lang="ts">
defineProps({
  componentStyle: {
    type: String as PropType<string>,
    default: ProductRenderStyle.DEFAULT,
  },
});

const { data: content } = useAsyncData(async () => {
  const response = await useBestSellers();
  return response;
});
</script>
