<template>
  <section class="lg:mt-25 px-6 lg:px-0 bg-primary-2-30 mt-16">
    <div class="container mx-auto pt-8 md:pt-15 pb-9 md:pb-17">
      <h2 class="font-bold text-primary-1-100 md:text-center text-lg md:text-2xl">
        {{ $t('title') }}
      </h2>

      <DelayHydration>
        <div class="mt-6 md:mt-11 grid grid-cols-1 md:grid-cols-3 gap-x-7 gap-y-11 md:gap-15">
          <div v-for="(block, index) in blockContent" :key="index" class="flex flex-col gap-y-4">
            <img :src="mediaUrl + block.image" :alt="block.title" class="w-6 h-6 object-contain" />
            <h3 class="text-primary-1-100 text-md font-bold">{{ block.title }}</h3>
            <p class="text-system-gray text-sm">{{ block.description }}</p>
          </div>
        </div>
      </DelayHydration>
    </div>
  </section>
</template>

<script setup lang="ts">
const {
  public: { mediaUrl },
} = useRuntimeConfig();
const { data: blockContent } = await useLazyAsyncData(async () => {
  const blockContent = await useHomeBlockSection();
  return blockContent;
});
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>

<style scoped>
img {
  max-width: 44px;
}
</style>

<i18n>
{
  "en": {
    "title": "A Unique Experience",
    "learnMore": "Learn More"
  },
  "ar": {
    "title": "تجربة فريدة",
    "learnMore": "اعرف أكثر"
  }
}
</i18n>
