import { useQuery } from 'villus';
import { Unpacked } from '~/types/utils';
import { OfferCardFragment } from '~/graphql/fragments';
import { HomeDocument, HomeQuery, HomeQueryVariables } from '~/graphql/home';

export enum HomeComponentType {
  'PRODUCTS_CAROUSEL',
  'RECENTLY_VIEWED_CAROUSEL',
  'BEST_SELLERS_CAROUSEL',
  'OFFERS',
  'FEATURED_CATEGORIES',
  'FEATURED_BRANDS',
  'ABOUT_BLOCKS',
}

export enum SliderRenderStyles {
  'MAIN' = 'MAIN',
  'THREE_CARDS_LEFT' = 'THREE_CARDS_LEFT',
  'THREE_CARDS_RIGHT' = 'THREE_CARDS_RIGHT',
  'BLOCKS' = 'BLOCKS',
  'DOUBLE_BANNER' = 'DOUBLE_BANNER',
}

export enum ProductRenderStyle {
  'DEFAULT',
  'INVERTED',
}

export type CarouselContent = ReturnType<typeof mapCarouselItems>;
export type OffersContent = ReturnType<typeof mapOffersItems>;

export type HomeComponentContent = CarouselContent | OffersContent | null;

export type HomeComponent<T> = {
  type: HomeComponentType;
  componentStyle: SliderRenderStyles | ProductRenderStyle;
  content: T;
};

export type HomeItem = Unpacked<Unpacked<HomeQuery['homeItems']>['items']>;

export async function useHomePage(variables: HomeQueryVariables) {
  const { cacheParam } = useSetLocaleToCacheParams();
  const pageCode = variables?.filter?.page_code || 'default';

  const { execute, isFetching } = useQuery({
    query: HomeDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
    variables,
  });

  const cachedIndex = useCachedSsrRef<HomeComponent<HomeComponentContent>[] | []>(cacheParam(`index_${pageCode}`), []);

  if (cachedIndex.value?.length) {
    return { homeContent: cachedIndex.value, isFetching };
  }

  const { data, error } = await execute();

  if (error && !data?.homeItems?.items?.length) {
    throw new Error(error?.message);
  }
  cachedIndex.value = data?.homeItems.items.sort(sortItem).map(homeItemsMapper) || [];

  return { homeContent: cachedIndex.value };
}

function sortItem(a: HomeItem | OfferCardFragment, b: HomeItem | OfferCardFragment) {
  return (a?.sort || Number.MAX_VALUE) - (b?.sort || Number.MAX_VALUE);
}

function homeItemsMapper(item: HomeItem) {
  return {
    content: homeContentMapper(item),
    type: homeTypeMapper(item),
    componentStyle: renderStyleMapper(item),
  };
}

function homeContentMapper(item: HomeItem): HomeComponentContent {
  switch (item?.type) {
    case 'carousel':
      return mapCarouselItems(item.carousel);

    case 'banner':
      return mapOffersItems(item);

    default:
      return null;
  }
}

function homeTypeMapper(item: HomeItem): HomeComponentType {
  switch (item?.type) {
    case 'carousel':
      return item.carousel?.code === 'recently-viewed'
        ? HomeComponentType.RECENTLY_VIEWED_CAROUSEL
        : item.carousel?.code === 'best-sellers'
        ? HomeComponentType.BEST_SELLERS_CAROUSEL
        : HomeComponentType.PRODUCTS_CAROUSEL;

    case 'banner':
      return HomeComponentType.OFFERS;

    case 'featured-brands':
      return HomeComponentType.FEATURED_BRANDS;

    case 'about-blocks':
      return HomeComponentType.ABOUT_BLOCKS;
    default:
      return HomeComponentType.FEATURED_CATEGORIES;
  }
}

function mapOffersItems(item: HomeItem) {
  return (
    item?.offers
      ?.filter(isValidOffer)
      ?.map(offer => mapHomeOffer(offer))
      ?.sort(sortItem) || []
  );
}

function renderStyleMapper(item: HomeItem): SliderRenderStyles | ProductRenderStyle {
  if (item?.type === 'banner')
    switch (item?.style) {
      case 'main':
        return SliderRenderStyles.MAIN;
      case 'three-cards-left':
        return SliderRenderStyles.THREE_CARDS_LEFT;
      case 'three-cards-right':
        return SliderRenderStyles.THREE_CARDS_RIGHT;
      case 'blocks':
        return SliderRenderStyles.BLOCKS;
      case 'double-banner':
        return SliderRenderStyles.DOUBLE_BANNER;

      default:
        return SliderRenderStyles.MAIN;
    }

  if (item?.type === 'carousel')
    switch (item?.style) {
      case 'inverted':
        return ProductRenderStyle.INVERTED;
      default:
        return ProductRenderStyle.DEFAULT;
    }

  return ProductRenderStyle.DEFAULT;
}
