<template>
  <div class="relative z-0">
    <DelayHydration>
      <Swiper
        :modules="[SwiperNavigation, SwiperAutoplay, SwiperPagination]"
        :slides-per-view="1"
        :centered-slides="false"
        :center-insufficient-slides="false"
        :loop="true"
        :slides-offset-before="0"
        :slides-offset-after="0"
        :space-between="15"
        :autoplay="{ delay: 5000 }"
        :pagination="{ el: '.swiper-pagination', clickable: true }"
        :breakpoints="{
          '400': {
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
          },
          '768': {
            slidesPerView: 1,
            spaceBetween: 20,
            centerInsufficientSlides: false,
            centeredSlides: false,
          },
        }"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        class="h-full swiper-container"
      >
        <SwiperSlide
          v-for="(item, idx) in offers"
          :key="idx"
          :class="{
            'active-slide': currentIndex + 1 === idx,
          }"
        >
          <AppLink :to="item.href" class="OfferImage mb-6 md:mb-0" external>
            <picture v-if="item">
              <AppSource :srcset="item.mobileImage" media="(max-width: 768px)" width="600" height="448" format="avif" />
              <AppSource :srcset="item.mobileImage" media="(max-width: 768px)" width="600" height="448" format="webp" />
              <AppSource :srcset="item.mobileImage" media="(max-width: 768px)" width="600" height="448" format="jpeg" />
              <AppSource :srcset="item.image" media="(min-width: 768px)" width="1700" height="664" format="avif" />
              <AppSource :srcset="item.image" media="(min-width: 768px)" width="1700" height="664" format="webp" />
              <AppSource :srcset="item.image" media="(min-width: 768px)" width="1700" height="664" format="jpeg" />
              <AppImage
                :src="item.image"
                :alt="String(item.title)"
                class="w-full object-cover"
                :loading="idx === 0 ? 'eager' : 'lazy'"
                :fetchpriority="idx === 0 ? 'high' : 'auto'"
              />
            </picture>
          </AppLink>
        </SwiperSlide>
        <SwiperControls :current-index="currentIndex" @change="e => (currentIndex = e)" />
      </Swiper>
      <template v-if="offers.length > 1">
        <div class="swiper-pagination"></div>
        <button
          class="hidden md:flex absolute left-5 top-1/2 transform -translate-y-1/2 w-12 h-12 z-10 outline-none [ flex items-center justify-center ]"
          aria-label="previous"
          @click="prevSlide"
        >
          <svg-icon-chevron-left
            :class="{ 'transform scale-x-reverse': $i18n.locale === 'ar' }"
            class="text-white stroke-current"
            width="24"
            height="24"
          />
        </button>

        <button
          class="hidden md:flex absolute right-5 top-1/2 transform -translate-y-1/2 w-12 h-12 z-10 outline-none [ flex items-center justify-center ]"
          aria-label="next"
          @click="nextSlide"
        >
          <svg-icon-chevron-right
            class="text-white stroke-current"
            :class="{ 'transform scale-x-reverse': $i18n.locale === 'ar' }"
            width="24"
            height="24"
          />
        </button>
      </template>
    </DelayHydration>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue';
import { OfferType } from '~/composables/offers';

const props = defineProps({
  offers: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
  type: {
    type: String as PropType<'default' | 'dimmed'>,
    default: 'default',
    validator: (value: string) => ['default', 'dimmed'].includes(value),
  },
});

const { t: $t } = useI18n({
  useScope: 'local',
});
const currentIndex = ref(0);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function nextSlide() {
  currentIndex.value = getValidValue(currentIndex.value + 1);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function prevSlide() {
  currentIndex.value = getValidValue(currentIndex.value - 1);
}

function getValidValue(num: number) {
  return clamp(0, props.offers.length - 1, num);
}
</script>
<style lang="postcss" scoped>
.OfferImage {
  width: 100%;

  /* @screen md {
    aspect-ratio: 2.53;
    width: 725px;
    height: 544px;
    flex-shrink: 0;
  } */

  img {
    aspect-ratio: 1.34;
    width: 100%;

    @screen md {
      aspect-ratio: 2.53;
    }
  }
}

.swiper-pagination-bullets {
  bottom: 32px;

  :deep(.swiper-pagination-bullet) {
    background: #fff;
    opacity: 1;

    &-active {
      background: #000;
    }
  }
}
</style>
<i18n>
  {
    "en": {
      "fashionDeals": "fashion deals",
      "next": "Next",
      "previous": "Previous",
      "viewOffer": "View offer"
    },
    "ar": {
      "fashionDeals": "عروض الموضة",
      "next": "التالي",
      "previous": "السابق",
      "viewOffer": "عرض العرض"
    }
  }
</i18n>
