<template>
  <div class="space-y-5 md:space-y-8">
    <component :is="item.component" :content="content" v-bind="item.attributes" :type="componentStyle" />
  </div>
</template>
<script setup lang="ts">
import { HomeComponentContent } from '@robustastudio/e-commerce/dist/runtime/composables/home';
import { PropType } from 'vue';

const componentMapper: Record<string, ReturnType<typeof resolveComponent>> = {
  [SliderRenderStyles.MAIN]: resolveComponent('HomeOffersBlockingSliderFragment'),
  [SliderRenderStyles.THREE_CARDS_LEFT]: resolveComponent('HomeOffersFragment'),
  [SliderRenderStyles.THREE_CARDS_RIGHT]: resolveComponent('HomeOffersFragment'),
  [SliderRenderStyles.BLOCKS]: resolveComponent('HomeOfferBlocksFragment'),
  [SliderRenderStyles.DOUBLE_BANNER]: resolveComponent('HomeDoubleBannerFragment'),
};

const props = defineProps({
  content: {
    type: Array as PropType<HomeComponentContent>,
    default: () => [],
  },
  componentStyle: {
    type: String,
    default: 'HomeOffersFragment',
  },
});

const item = computed(() => ({
  component: componentMapper[props.componentStyle],
  attributes: {},
}));
</script>
