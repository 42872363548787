<template>
  <div class="px-5 md:px-0 container mx-auto mt-20 md:mt-35 flex flex-col-reverse md:flex-row gap-y-6 md:gap-x-15">
    <div
      v-if="firstBanner.title || firstBanner.description || firstBanner.cta"
      class="flex flex-col justify-center md:w-1/3"
    >
      <h2 v-if="firstBanner.title" class="text-primary-1-100 font-bold text-lg md:text-2xl mb-2">
        {{ firstBanner.title }}
      </h2>
      <p v-if="firstBanner.description" class="text-system-gray text-sm mb-6">{{ firstBanner.description }}</p>
      <AppButton v-if="firstBanner.cta" as="AppLink" :to="firstBanner.href" external class="">{{
        firstBanner.cta
      }}</AppButton>
    </div>
    <div class="OfferImages flex gap-x-6 md:gap-x-10 justify-between">
      <picture>
        <AppSource
          v-if="firstBanner?.mobileImage"
          :srcset="firstBanner?.mobileImage"
          media="(max-width: 768px)"
          width="200"
          height="255"
          format="avif"
        />
        <AppSource
          v-if="firstBanner?.mobileImage"
          :srcset="firstBanner?.mobileImage"
          media="(max-width: 768px)"
          width="200"
          height="255"
          format="webp"
        />
        <AppSource
          v-if="firstBanner?.mobileImage"
          :srcset="firstBanner?.mobileImage"
          media="(max-width: 768px)"
          width="200"
          height="255"
          format="jpeg"
        />
        <AppSource
          :srcset="String(firstBanner?.image)"
          media="(min-width: 768px)"
          width="400"
          height="514"
          format="avif"
        />
        <AppSource
          :srcset="String(firstBanner?.image)"
          media="(min-width: 768px)"
          width="400"
          height="514"
          format="webp"
        />
        <AppSource
          :srcset="String(firstBanner?.image)"
          media="(min-width: 768px)"
          width="400"
          height="514"
          format="jpeg"
        />
        <AppImage
          :src="String(firstBanner?.image)"
          :alt="String(firstBanner?.title)"
          class="mt-10"
          loading="eager"
          fetchpriority="high"
        />
      </picture>
      <picture>
        <AppSource
          v-if="secondBanner?.mobileImage"
          :srcset="secondBanner?.mobileImage"
          media="(max-width: 768px)"
          width="200"
          height="255"
          format="avif"
        />
        <AppSource
          v-if="secondBanner?.mobileImage"
          :srcset="secondBanner?.mobileImage"
          media="(max-width: 768px)"
          width="200"
          height="255"
          format="webp"
        />
        <AppSource
          v-if="secondBanner?.mobileImage"
          :srcset="secondBanner?.mobileImage"
          media="(max-width: 768px)"
          width="200"
          height="255"
          format="jpeg"
        />
        <AppSource
          :srcset="String(secondBanner?.image)"
          media="(min-width: 768px)"
          width="400"
          height="514"
          format="avif"
        />
        <AppSource
          :srcset="String(secondBanner?.image)"
          media="(min-width: 768px)"
          width="400"
          height="514"
          format="webp"
        />
        <AppSource
          :srcset="String(secondBanner?.image)"
          media="(min-width: 768px)"
          width="400"
          height="514"
          format="jpeg"
        />
        <AppImage
          :src="String(secondBanner?.image)"
          :alt="String(secondBanner?.title)"
          loading="eager"
          fetchpriority="high"
        />
      </picture>
    </div>
  </div>
</template>

<script setup lang="ts">
import { OffersContent } from 'composables/home';

const props = defineProps({
  content: {
    type: Array as PropType<OffersContent[]>,
    default: () => [],
  },
});

const firstBanner: ComputedRef<OffersContent> = computed(() => props.content[0]);
const secondBanner: ComputedRef<OffersContent> = computed(() => props.content[1]);
</script>

<style lang="postcss" scoped>
.OfferImages {
  img {
    aspect-ratio: 0.78;
    width: 170px;

    @screen md {
      width: 380px;
    }
  }
}
</style>
