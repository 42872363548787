<template>
  <div class="flex flex-col md:flex-row justify-center">
    <AppLink :to="firstBanner?.href || '/'" class="relative" external>
      <picture>
        <AppSource
          v-if="firstBanner?.mobileImage"
          :srcset="firstBanner?.mobileImage"
          media="(max-width: 768px)"
          width="500"
          height="665"
          format="avif"
        />
        <AppSource
          v-if="firstBanner?.mobileImage"
          :srcset="firstBanner?.mobileImage"
          media="(max-width: 768px)"
          width="500"
          height="665"
          format="webp"
        />
        <AppSource
          v-if="firstBanner?.mobileImage"
          :srcset="firstBanner?.mobileImage"
          media="(max-width: 768px)"
          width="500"
          height="665"
          format="jpeg"
        />
        <AppSource
          :srcset="String(firstBanner?.image)"
          media="(min-width: 768px)"
          width="1000"
          height="1330"
          format="avif"
        />
        <AppSource
          :srcset="String(firstBanner?.image)"
          media="(min-width: 768px)"
          width="1000"
          height="1330"
          format="webp"
        />
        <AppSource
          :srcset="String(firstBanner?.image)"
          media="(min-width: 768px)"
          width="1000"
          height="1330"
          format="jpeg"
        />
        <AppImage :src="String(firstBanner?.image)" :alt="String(firstBanner?.title)" class="w-full" loading="lazy" />
      </picture>
      <span
        class="[ text-lg text-white font-bold underline ] [ absolute bottom-10 left-1/2 transform -translate-x-1/2 ]"
        >{{ firstBanner?.cta }}</span
      >
    </AppLink>
    <AppLink :to="secondBanner?.href || ''" class="relative" external>
      <picture>
        <AppSource
          v-if="secondBanner?.mobileImage"
          :srcset="secondBanner?.mobileImage"
          media="(max-width: 768px)"
          width="500"
          height="665"
          format="avif"
        />
        <AppSource
          v-if="secondBanner?.mobileImage"
          :srcset="secondBanner?.mobileImage"
          media="(max-width: 768px)"
          width="500"
          height="665"
          format="webp"
        />
        <AppSource
          v-if="secondBanner?.mobileImage"
          :srcset="secondBanner?.mobileImage"
          media="(max-width: 768px)"
          width="500"
          height="665"
          format="jpeg"
        />
        <AppSource
          :srcset="String(secondBanner?.image)"
          media="(min-width: 768px)"
          width="1000"
          height="1330"
          format="avif"
        />
        <AppSource
          :srcset="String(secondBanner?.image)"
          media="(min-width: 768px)"
          width="1000"
          height="1330"
          format="webp"
        />
        <AppSource
          :srcset="String(secondBanner?.image)"
          media="(min-width: 768px)"
          width="1000"
          height="1330"
          format="jpeg"
        />
        <AppImage :src="String(secondBanner?.image)" :alt="String(secondBanner?.title)" class="w-full" loading="lazy" />
      </picture>
      <span
        class="[ text-lg text-white font-bold underline ] [ absolute bottom-10 left-1/2 transform -translate-x-1/2 ]"
        >{{ secondBanner?.cta }}</span
      >
    </AppLink>
  </div>
</template>

<script setup lang="ts">
import { OfferType } from '~/composables/offers';

const props = defineProps({
  content: {
    type: Array as PropType<OfferType>,
    default: () => [],
  },
});

const firstBanner = ref<OfferType[number]>();
const secondBanner = ref<OfferType[number]>();

onMounted(() => {
  firstBanner.value = props.content[0];

  secondBanner.value = props.content[1];
});
</script>

<style lang="postcss" scoped>
a.relative {
  min-width: 50%;
}
</style>
